import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Modal, Carousel, Button, ProgressBar } from "react-bootstrap"

import checkmarkIcon from "../images/checkmark.svg";


const Test = ({show, handleClose, handleShowStopLight, addUser}) => {

  const [optionState, setOptionState] = useState({});
  const [carouselIndex, setCarouselIndex] = useState(0);

  //staticquery
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestPage {
        edges {
          node {
            backButtonLabel
            cancelButtonLabel
            yesButtonLabel
            noButtonLabel
            nextButtonLabel
            questions {
              question
              detail
              options
              redLight {
                type
              } 
            }
          }
        }
      }
    }
  `);
  const fields = data.allContentfulTestPage.edges[0].node;
  const questions = fields.questions;
  const length = questions.length;

  const nextHandler = (index) => {
    const id = "question-" + index;
    const answer = optionState[id].answer;
    const selectedOptions = optionState[id].selectedOptions;
    
    if ( answer === "yes" ) {
      // console.log( optionState[id].errorType );
      handleShowStopLight( optionState[id].errorType )
      addUser('failed');
      return
    }

    if ( Array.isArray(selectedOptions) ) {
      if (selectedOptions.length > 0) {
        handleShowStopLight( optionState[id].errorType );
        addUser('failed');
        return
      } 
    };

    if ( index === length - 1 ) {
      handleShowStopLight( 'success' );
      addUser('passed');
      return
    }

    setTimeout(function(){
      setCarouselIndex(prevIndex => prevIndex + 1);
    }, 325)
  }

  const cancelHandler = () => {
    setCarouselIndex(0);
    handleClose();
  }

  const backHandler = () => {
    setCarouselIndex(prevIndex => prevIndex - 1);
  }

  const [finalState, setFinalState] = useState(false);



  const handleBooleanClick = (index, answer) => {
    const id ="question-"+index;

    setOptionState(prevState => {
      const data = prevState[id];
      data.nextDisabled = false;
      data.answer = answer;
      data.selectedOptions = [];

      return {
        ...prevState,
        [id]: data
      }
    })
  }

  const handleMultiClick = (index, answer) => {
    const id = "question-"+index;
    // console.log(answer);
 

    setOptionState(prevState => {
      const data = prevState[id];
      data.nextDisabled = false;
      data.answer = '';
      if (data.selectedOptions.indexOf(answer) === -1) {
        data.selectedOptions.push(answer);
      } else {
        data.selectedOptions.splice( data.selectedOptions.indexOf(answer), 1);
      }

      return {
        ...prevState,
        [id]: data
      }
    })
  }

  useEffect(()=>{
    // console.log('called');
    fields.questions.forEach((question, index) => {
      setOptionState(prevState => {
        const id = "question-"+index;
        const type = question.redLight ? question.redLight.type : 'default';

        return {
          ...prevState,
          [id]: {
            nextDisabled: true,
            selectedOptions: [],
            errorType: type
          }
        }
      })
    })
  }, [fields]);


  return (
    <Modal show={show} onHide={handleClose} backdrop="static">  
      <ProgressBar now={ (carouselIndex / (length -1) ) * 100} className="w-100" variant="secondary"/>
      <Modal.Header className="d-flex align-items-center justify-content-between bg-light text-primary">
        <div>
          { carouselIndex > 0 ? <p className="modal-back btn-back mb-0" onClick={ backHandler }><span className="chevron left"></span> { fields.backButtonLabel }</p> : '' }
        </div>
        <div>
          <p className="modal-close mb-0" onClick={cancelHandler}>{ fields.cancelButtonLabel }</p>
        </div>
      </Modal.Header>

      <Modal.Body className="bg-light h-100 overflow-hidden p-0">
        <Carousel
          activeIndex={ carouselIndex }
          indicators={false}
          controls={false}
          interval={null}
          keyboard={false}
          touch={false}
          className="h-100"
        >
          { questions.map((question, index) => {
            
            const id = 'question-'+index;
            // console.log(optionState);
            // console.log( optionState[id] );
            return (
              <Carousel.Item key={index} className="h-100 px-md-10">
                <div className="p-3">
                  <h2 className="text-dark">{question.question}</h2>
                  { question.detail ? <p className="mb-0 text-primary">{question.detail}</p> : '' }
                </div>
                <div className="p-3 overflow-y-scroll">
                  <div className="options mb-5">
                  { question.options ? 
                    <>
                      { question.options.map((option, qIndex) => (    
                        <Button
                          key={qIndex}
                          onClick={ () => handleMultiClick(index, qIndex) }
                          data-toggle="button" size="lg" variant={'light'} 
                          className={`text-left mb-3 p-4 ${ optionState[id] && optionState[id].selectedOptions.indexOf(qIndex) > -1 ? "active" : ''}`}
                          block>
                            
                          <div className="d-flex align-items-center justify-content-between">
                            {option} 

                            <div className={`checkmark ${optionState[id] && optionState[id].selectedOptions.indexOf(qIndex) > -1 ? "active" : ""}`}>
                              {optionState[id] && optionState[id].selectedOptions.indexOf(qIndex) > -1 ? <img src={ checkmarkIcon } alt="checkmark" /> : ""}
                            </div>
                          </div>
                        </Button>
                      ))
                      }
                      <Button 
                          onClick={ () => handleBooleanClick(index, 'no')}
                          size="lg" variant={'light'} 
                          className={`text-left mb-3 p-4 ${ optionState[id] && optionState[id].answer === "no" ? "active" : ''}`} 
                          block>

                        <div className="d-flex align-items-center justify-content-between">
                          {fields.noButtonLabel} 

                          <div className={`checkmark ${optionState[id] && optionState[id].answer === "no" ? "active" : ""}`}>
                            {optionState[id] && optionState[id].answer === "no" ? <img src={ checkmarkIcon } alt="checkmark"  /> : ""}
                          </div>
                        </div>
                      </Button>
                    </>
                    :
                    <>
                      <Button 
                        onClick={ () => handleBooleanClick(index, 'yes')}
                        size="lg" variant={'light'}
                        className={`text-left mb-3 p-4 ${ optionState[id] && optionState[id].answer === "yes" ? "active" : ''}`} 
                        block>

                        <div className="d-flex align-items-center justify-content-between">
                          {fields.yesButtonLabel} 

                          <div className={`checkmark ${optionState[id] && optionState[id].answer === "yes" ? "active" : ""}`}>
                            {optionState[id] && optionState[id].answer === "yes" ? <img src={ checkmarkIcon } alt="checkmark"  /> : ""}
                          </div>
                        </div>
                      </Button>
                      <Button 
                        onClick={ () => handleBooleanClick(index, 'no')}
                        size="lg" variant={'light'} 
                        className={`text-left mb-3 p-4 ${ optionState[id] && optionState[id].answer === "no" ? "active" : ''}`} 
                        block>
                          
                        <div className="d-flex align-items-center justify-content-between">
                          {fields.noButtonLabel} 

                          <div className={`checkmark ${optionState[id] && optionState[id].answer === "no" ? "active" : ""}`}>
                            {optionState[id] && optionState[id].answer === "no" ? <img src={ checkmarkIcon } alt="checkmark"  /> : ""}
                          </div>
                        </div>
                      </Button>
                    </>
                  }
                  </div>

                  <Button 
                    size="lg" 
                    variant={'secondary'} 
                    className="text-dark mb-0 p-3" 
                    disabled={ optionState[id] ? optionState[id].nextDisabled : true }
                    block
                    onClick={ () => nextHandler(index) }
                  >
                    {fields.nextButtonLabel}
                  </Button>

                </div>

              </Carousel.Item>
            )
          })}
        </Carousel>
      </Modal.Body>      
    </Modal>
  )
}

export default Test