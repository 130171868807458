import React from "react"
import { Navbar } from "react-bootstrap"
import logo from "../images/logo.png"

const Header = () => {
  return (
    <Navbar fixed="top" bg="dark" variant="dark">
      <Navbar.Brand href="#home">
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top mr-2"
        />{'  '}
        Green Light
      </Navbar.Brand>
    </Navbar>
  )
}

export default Header