import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Carousel } from "react-bootstrap"
import Layout from "../components/layout"
import Test from "../components/test"
import StopLight from "../components/stop-light"
import firebase from "gatsby-plugin-firebase"
import { createClient } from "contentful";
import parse from 'html-react-parser';

import { dateFormat, customEvent, getCurrentDate } from "../helpers"

import Logo from "../images/kruger_products_circle-logo.svg"

import config from "../config.json";

const client = createClient({
  accessToken: config.accessToken,
  space: config.space 
})

const Home = ({data}) => {

  const [ isInvalid, setIsInvalid ] = useState(false);
  const [ hasContent, setHasContent ] = useState(false);
  const [show, setShow] = useState(false);
  const [showStopLight, setShowStopLight] = useState(false);
  const [stopLightState, setStopLightState] = useState('no-access');
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [activeVisitors, setActiveVisitors] = useState([]);

  const email = useRef(null);
  const fields = data.allContentfulHomePage.edges[0].node;

  
  //Test Modal Handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Stoplight Modal Handlers
  const handleCloseStopLight = () => setShowStopLight(false);
  const handleShowStopLight = (type = 'default') => {
    const userEmail = email.current.value.toLowerCase();
    let result = 'Red Light: ' + type;
    if ( type === 'success') {
      result = 'Green Light';
    }

    customEvent(`${getCurrentDate()}`, 'Handle Stop Light', `${userEmail} ${result}`);

    setStopLightState(type);
    setShowStopLight(true);
  }

  //Check the Email, launch the modal
  const startTest = (e) => {
    e.preventDefault();

    let hasAccess = 'Red Light: no access';
    const userEmail = email.current.value.toLowerCase();

    if ( !email.current.checkValidity() || userEmail === "" ) {
      setIsInvalid(true)
      return
    } else {
      setIsInvalid(false)
    }
    
    //check if email matches list
    if ( activeVisitors.includes(userEmail) ) {
      hasAccess = 'Start Test';
      customEvent(`${getCurrentDate()}`, 'Begin Button Submit', `${userEmail} ${hasAccess}`);
      handleShow()
    } else {
      handleShowStopLight('no-access');
    }

  }

  const checkInput = () => {
    const userEmail = email.current.value;

    if (userEmail.length > 0) {
      setHasContent(true);
    } else {
      setHasContent(false)
    }
  }

  //Send Email
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: `Green Light submission`,
    message: "",
  })

  const sendEmail = async (e) => {
    const userEmail = email.current.value.toLowerCase();
  
    try{
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        body: JSON.stringify(formState),
      })
  
      if (!response.ok) {
        //not 200 response
        return
      }
      //all OK
      customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Success`);

      
    } catch(e){
      customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Fail`);
      //error
    }
  }

  //get contentful active users
  useEffect(()=>{
    // console.log('updating active users');
    client.getEntries({
      limit: 500,
      content_type: "visitor"
    }).then(function (entries) {
      entries.items.forEach(function(entry) {
        if (entry.fields.active) {
          setActiveVisitors( prevState => {
            return [
              ...prevState,
              entry.fields.email.toLowerCase()
            ]
          })
        }
      })
    })

  }, [])

  const addUser = ( status = "failed") => {
    const today = new Date();
    const date = dateFormat.format(new Date(today))
    const userEmail = email.current.value.toLowerCase()
    const visitor = data.allContentfulVisitor.edges.find(obj => obj.node.email.toLowerCase() === userEmail);
    // console.log(visitor.node.email);
    const message = `
      Hi ${visitor.node.firstName},

      Thank you for completing the Green Light pre-screening questionnaire – please see below for your confirmation.

      Date: ${ date }
      E-mail: ${ visitor.node.email}
      Status: ${ status }
      Zone: ${ visitor.node.department.zone }

      Please remember to complete this questionnaire each time you travel into the office.

      Human Resources
      Kruger Products
    `;

    setFormState(prevState => {
      return {
        ...prevState,
        name: `${visitor.node.firstName} ${visitor.node.lastName}`,
        email: `${visitor.node.email}`,
        message: message
      }
    })
    
    firebase.database().ref("/visitors/" + visitor.node.id).set({ status: status },
      function(error) {
        if (error) {
          customEvent(`${getCurrentDate()}`, 'Firebase Set', `${userEmail} write failed`);
        } else {
          customEvent(`${getCurrentDate()}`, 'Firebase Set', `${userEmail} write success`);
        }
      });
  }

  useEffect(()=>{
    if (formState.message !== "") {
      sendEmail();
    }
    
  }, [formState])

  const preScreenHandler = () => {
    customEvent('Button', 'Click', 'Pre-Screen');
    // typeof window !== "undefined" && window.gtag("event", "click", { 
    //   'event_category' : 'Button',
    //   'event_label' : 'Step 2: Pre-Screen' 
    // })

    setTimeout( () => {
      setCarouselIndex(prevIndex => prevIndex + 1);
    }, 350)
  }

  return (
    <Layout>
      <section className="text-white">
        <StopLight type={ stopLightState } show={showStopLight} handleClose={handleCloseStopLight} />
        <Test 
          show={show} 
          handleClose={handleClose} 
          handleShowStopLight={handleShowStopLight}
          addUser={ addUser }
        />

        <Container className="h-100 py-4">
          <Row className="h-100 d-flex align-items-center">
            <Col className="text-center p-0 bg-primary">
              <img src={Logo} className="mb-3 main-logo" alt="Kruger Products Logo"/>
              <h1 className="text-center mb-4">Green Light</h1>
              <Carousel
                activeIndex={ carouselIndex }
                indicators={false}
                controls={false}
                interval={null}
                keyboard={false}
                touch={false}
                className="carousel-home"
              >
                <Carousel.Item className="px-3">
                  <div className="text-center mb-4">{ parse(fields.splashCopy.splashCopy) }</div>

                  <a className="btn btn-secondary btn-lg d-block text-dark py-3 mb-4" size="lg" target="_blank" href="https://book.timify.com/services?accountId=5f22dbfb72760412e34280df&hideCloseButton=true">
                    { fields.registerButtonLabel }
                  </a>

                  <Button variant="secondary" className="btn-white text-dark py-3" size="lg" onClick={ preScreenHandler } block>
                    { fields.preScreenButtonLabel }
                  </Button>

                </Carousel.Item>
                <Carousel.Item className="px-3">
                  <h2 className="text-light text-center">{ fields.introductionHeadline }</h2>
                  <div className="text-center mb-5">{ parse(fields.introductionCopy.introductionCopy) }</div>
                  <Form onSubmit={ startTest }>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control 
                        ref={email} 
                        onChange={ checkInput } 
                        type="email" 
                        pattern="[^@]+@[^@]+\.[a-zA-Z]{2,6}"
                        required/>
                      <Form.Label className={`text-primary ${isInvalid ? 'invalid' : ''} ${hasContent ? 'has-content' : ''}`}>{ fields.emailInputLabel }</Form.Label>
                    </Form.Group>

                    <Button variant="secondary" className="text-dark py-3" size="lg" type="submit" block>
                      { fields.emailButtonLabel }
                    </Button>
                  </Form>
                </Carousel.Item>
              </Carousel>
              
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Home

export const query = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allContentfulHomePage {
    edges {
      node {
        splashCopy {
          splashCopy
        }
        registerButtonLabel
        preScreenButtonLabel
        introductionHeadline
        introductionCopy {
          introductionCopy
        }
        emailInputLabel
        emailButtonLabel
      }
    }
  }
  allContentfulVisitor {
    edges {
      node {
        id: contentful_id
        firstName
        lastName
        email
        department {
          departmentName
          zone
        }
      }
    }
  }
}
`
