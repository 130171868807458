import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Modal, Container, Row, Col } from "react-bootstrap"
import RedLightImage from "../images/red-light.png"
import GreenLightImage from "../images/green-light.png"
import phoneIcon from "../images/phone-icon.png";

const StopLight = ({ show, handleClose, type }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulRedLightPage {
        edges {
          node {
            title
            greenLightTitle
            phoneLabel
            phoneNumber
            emailLabel
            emailAddress
          }
        }
      }
      allContentfulStopLight {
        edges {
          node {
            type
            headline
            copy {
              copy
            }
            displayContact 
          }
        }
      }

    }
  `);

  const generalFields = data.allContentfulRedLightPage.edges[0].node;
  const stopLights = data.allContentfulStopLight.edges;
  // console.log(stopLights);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static"> 
      <Modal.Body className="bg-light h-100 p-0">
        <Container>
          { stopLights.map((item, index) => {
            return (
            <Row key={index} className={`my-5 ${ type == item.node.type ? 'd-block' : 'd-none'}`}>
              <Col className="text-center" md={{span: 6, offset: 3 }}>
                <img 
                  src={ type === 'success' ? GreenLightImage : RedLightImage } 
                  alt="red light" className="w-25 mb-3"/>
                <h2 className="text-dark">{ type === 'success' ? generalFields.greenLightTitle : generalFields.title }</h2>
                <p className="text-primary mb-1"><strong>{ item.node.headline } </strong></p>
                <p className="text-primary px-3 mb-4">{ item.node.copy.copy }</p>

                { item.node.displayContact ? 
                  <>
                    <a href={`mailto:${ generalFields.emailAddress}`} target="_blank" className="btn btn-secondary btn-lg p-3 px-5 text-dark mb-4">
                      <div className="d-flex align-items-center justify-content-between">
                        
                        { generalFields.emailLabel }
                      </div>
                    </a>
                  </> : ''}
              </Col>
            </Row>
            )
          })}
          
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default StopLight