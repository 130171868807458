import React from "react"
import Header from "./header"
import Footer from "./footer"
import {Helmet} from "react-helmet";

import "../styles/index.scss"


const Layout = (props) => {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kruger Greenlight - Home</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00a300" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>

    <div className={`container-layout bg-primary`}>
      <div className="content-layout">
        <Header />
        { props.children }
      </div>
      <Footer />
    </div>
    </>
  )
}

export default Layout